import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};

export type Clazz = 
  'druid' |
  'hunter' |
  'mage' |
  'paladin' |
  'priest' |
  'rogue' |
  'shaman' |
  'warlock' |
  'warrior';

export type Difficulty = 
  'easy' |
  'medium' |
  'hard';

export type Ding = {
  __typename?: 'Ding',
  key: Scalars['String'],
  title: Scalars['String'],
  fields?: Maybe<Array<Maybe<DingField>>>,
};

export type DingField = {
  __typename?: 'DingField',
  description: Scalars['String'],
};

export type Faction = 
  'alliance' |
  'horde' |
  'both';

export type FlightClass = 
  'druid';

export type FlightPath = {
  __typename?: 'FlightPath',
  endpoints?: Maybe<Array<Maybe<Scalars['String']>>>,
  faction?: Maybe<Faction>,
  duration?: Maybe<Scalars['Int']>,
  transport?: Maybe<FlightTransport>,
  label?: Maybe<Scalars['String']>,
  class?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['Int']>,
  item?: Maybe<Scalars['String']>,
};

export type FlightPoint = {
  __typename?: 'FlightPoint',
  location?: Maybe<Scalars['String']>,
  continent?: Maybe<Scalars['Int']>,
  faction?: Maybe<Faction>,
  globalLoc?: Maybe<Scalars['String']>,
  mapLoc?: Maybe<Scalars['String']>,
  hearthstone?: Maybe<Scalars['Boolean']>,
  type?: Maybe<FlightType>,
  class?: Maybe<Scalars['String']>,
  phase?: Maybe<Scalars['Int']>,
  levels?: Maybe<Levels>,
  difficulty?: Maybe<Difficulty>,
  canonical?: Maybe<Scalars['String']>,
};

export type FlightRoutes = {
  __typename?: 'FlightRoutes',
  paths?: Maybe<Array<Maybe<FlightPath>>>,
};

export type FlightTransport = 
  'flight' |
  'tram' |
  'zeppelin' |
  'ship' |
  'spell' |
  'item' |
  'quest' |
  'travel';

export type FlightType = 
  'city' |
  'dungeon' |
  'raid';

export type FlightZone = {
  __typename?: 'FlightZone',
  levels?: Maybe<Levels>,
};

export type Levels = {
  __typename?: 'Levels',
  entry?: Maybe<Scalars['Int']>,
  min?: Maybe<Scalars['Int']>,
  max?: Maybe<Scalars['Int']>,
};

export type Name = {
  __typename?: 'Name',
  name?: Maybe<Scalars['String']>,
};

export type PointInfo = {
  __typename?: 'PointInfo',
  point?: Maybe<FlightPoint>,
  connectedPaths?: Maybe<Array<Maybe<FlightPath>>>,
};

export type Query = {
  __typename?: 'Query',
  _empty?: Maybe<Scalars['String']>,
  getDing?: Maybe<Array<Maybe<Ding>>>,
  getPoints?: Maybe<Array<Maybe<FlightPoint>>>,
  getPoint?: Maybe<PointInfo>,
  getRoutes?: Maybe<Array<Maybe<FlightRoutes>>>,
  getNames?: Maybe<Array<Maybe<Name>>>,
  getRaces?: Maybe<Array<Maybe<Race>>>,
};


export type QueryGetDingArgs = {
  level?: Maybe<Scalars['Int']>,
  clazz?: Maybe<Clazz>,
  race?: Maybe<Race>,
  phase?: Maybe<Scalars['Int']>,
  faction?: Maybe<Faction>
};


export type QueryGetPointArgs = {
  location: Scalars['String']
};


export type QueryGetRoutesArgs = {
  faction: Scalars['String'],
  fromLoc: Scalars['String'],
  toLoc: Scalars['String'],
  druid?: Maybe<Scalars['Boolean']>,
  mage?: Maybe<Scalars['Int']>,
  everlookTeleporter?: Maybe<Scalars['Boolean']>,
  gadgetzanTeleporter?: Maybe<Scalars['Boolean']>,
  oppositeFaction?: Maybe<Scalars['Boolean']>,
  hearthstone?: Maybe<Scalars['String']>
};


export type QueryGetNamesArgs = {
  numWords?: Maybe<Scalars['Int']>,
  startLetters?: Maybe<Scalars['String']>,
  minLength?: Maybe<Scalars['Int']>,
  maxLength?: Maybe<Scalars['Int']>,
  race?: Maybe<Race>
};

export type Race = 
  'dwarf' |
  'gnome' |
  'human' |
  'nightelf' |
  'orc' |
  'tauren' |
  'troll' |
  'undead';

export type SortBy = 
  'duration' |
  'shortest';
export type FlightPointFragmentFragment = (
  { __typename?: 'FlightPoint' }
  & Pick<FlightPoint, 'location' | 'continent' | 'faction' | 'globalLoc' | 'hearthstone' | 'type' | 'class' | 'phase'>
  & { levels: Maybe<(
    { __typename?: 'Levels' }
    & Pick<Levels, 'entry' | 'min' | 'max'>
  )> }
);

export type GetDingQueryVariables = {
  level?: Maybe<Scalars['Int']>,
  clazz?: Maybe<Clazz>,
  race?: Maybe<Race>,
  faction?: Maybe<Faction>,
  phase?: Maybe<Scalars['Int']>
};


export type GetDingQuery = (
  { __typename?: 'Query' }
  & { getDing: Maybe<Array<Maybe<(
    { __typename?: 'Ding' }
    & Pick<Ding, 'key' | 'title'>
    & { fields: Maybe<Array<Maybe<(
      { __typename?: 'DingField' }
      & Pick<DingField, 'description'>
    )>>> }
  )>>> }
);

export type GetNamesQueryVariables = {
  minLength: Scalars['Int'],
  maxLength: Scalars['Int'],
  startLetters: Scalars['String'],
  race?: Maybe<Race>
};


export type GetNamesQuery = (
  { __typename?: 'Query' }
  & { getNames: Maybe<Array<Maybe<(
    { __typename?: 'Name' }
    & Pick<Name, 'name'>
  )>>> }
);

export type GetPointsQueryVariables = {};


export type GetPointsQuery = (
  { __typename?: 'Query' }
  & { getPoints: Maybe<Array<Maybe<{ __typename?: 'FlightPoint' }
    & FlightPointFragmentFragment
  >>> }
);

export type GetRoutesQueryVariables = {
  faction: Scalars['String'],
  fromLoc: Scalars['String'],
  toLoc: Scalars['String'],
  druid?: Maybe<Scalars['Boolean']>,
  mage?: Maybe<Scalars['Int']>,
  everlookTeleporter?: Maybe<Scalars['Boolean']>,
  gadgetzanTeleporter?: Maybe<Scalars['Boolean']>,
  oppositeFaction?: Maybe<Scalars['Boolean']>,
  hearthstone?: Maybe<Scalars['String']>
};


export type GetRoutesQuery = (
  { __typename?: 'Query' }
  & { getRoutes: Maybe<Array<Maybe<(
    { __typename?: 'FlightRoutes' }
    & { paths: Maybe<Array<Maybe<(
      { __typename?: 'FlightPath' }
      & Pick<FlightPath, 'endpoints' | 'faction' | 'duration' | 'transport' | 'label' | 'class'>
    )>>> }
  )>>> }
);
export const FlightPointFragmentFragmentDoc = gql`
    fragment FlightPointFragment on FlightPoint {
  location
  continent
  faction
  globalLoc
  hearthstone
  type
  class
  phase
  levels {
    entry
    min
    max
  }
}
    `;
export const GetDingDocument = gql`
    query getDing($level: Int, $clazz: Clazz, $race: Race, $faction: Faction, $phase: Int) {
  getDing(level: $level, clazz: $clazz, race: $race, faction: $faction, phase: $phase) {
    key
    title
    fields {
      description
    }
  }
}
    `;
export type GetDingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDingQuery, GetDingQueryVariables>, 'query'>;

    export const GetDingComponent = (props: GetDingComponentProps) => (
      <ApolloReactComponents.Query<GetDingQuery, GetDingQueryVariables> query={GetDingDocument} {...props} />
    );
    

    export function useGetDingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDingQuery, GetDingQueryVariables>) {
      return ApolloReactHooks.useQuery<GetDingQuery, GetDingQueryVariables>(GetDingDocument, baseOptions);
    };
      export function useGetDingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDingQuery, GetDingQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetDingQuery, GetDingQueryVariables>(GetDingDocument, baseOptions);
      };
      
export type GetDingQueryHookResult = ReturnType<typeof useGetDingQuery>;
export type GetDingQueryResult = ApolloReactCommon.QueryResult<GetDingQuery, GetDingQueryVariables>;
export const GetNamesDocument = gql`
    query getNames($minLength: Int!, $maxLength: Int!, $startLetters: String!, $race: Race) {
  getNames(minLength: $minLength, maxLength: $maxLength, startLetters: $startLetters, race: $race) {
    name
  }
}
    `;
export type GetNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNamesQuery, GetNamesQueryVariables>, 'query'> & ({ variables: GetNamesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetNamesComponent = (props: GetNamesComponentProps) => (
      <ApolloReactComponents.Query<GetNamesQuery, GetNamesQueryVariables> query={GetNamesDocument} {...props} />
    );
    

    export function useGetNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNamesQuery, GetNamesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetNamesQuery, GetNamesQueryVariables>(GetNamesDocument, baseOptions);
    };
      export function useGetNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNamesQuery, GetNamesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetNamesQuery, GetNamesQueryVariables>(GetNamesDocument, baseOptions);
      };
      
export type GetNamesQueryHookResult = ReturnType<typeof useGetNamesQuery>;
export type GetNamesQueryResult = ApolloReactCommon.QueryResult<GetNamesQuery, GetNamesQueryVariables>;
export const GetPointsDocument = gql`
    query getPoints {
  getPoints {
    ...FlightPointFragment
  }
}
    ${FlightPointFragmentFragmentDoc}`;
export type GetPointsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPointsQuery, GetPointsQueryVariables>, 'query'>;

    export const GetPointsComponent = (props: GetPointsComponentProps) => (
      <ApolloReactComponents.Query<GetPointsQuery, GetPointsQueryVariables> query={GetPointsDocument} {...props} />
    );
    

    export function useGetPointsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPointsQuery, GetPointsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetPointsQuery, GetPointsQueryVariables>(GetPointsDocument, baseOptions);
    };
      export function useGetPointsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPointsQuery, GetPointsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetPointsQuery, GetPointsQueryVariables>(GetPointsDocument, baseOptions);
      };
      
export type GetPointsQueryHookResult = ReturnType<typeof useGetPointsQuery>;
export type GetPointsQueryResult = ApolloReactCommon.QueryResult<GetPointsQuery, GetPointsQueryVariables>;
export const GetRoutesDocument = gql`
    query getRoutes($faction: String!, $fromLoc: String!, $toLoc: String!, $druid: Boolean, $mage: Int, $everlookTeleporter: Boolean, $gadgetzanTeleporter: Boolean, $oppositeFaction: Boolean, $hearthstone: String) {
  getRoutes(faction: $faction, fromLoc: $fromLoc, toLoc: $toLoc, druid: $druid, mage: $mage, everlookTeleporter: $everlookTeleporter, gadgetzanTeleporter: $gadgetzanTeleporter, oppositeFaction: $oppositeFaction, hearthstone: $hearthstone) {
    paths {
      endpoints
      faction
      duration
      transport
      label
      class
    }
  }
}
    `;
export type GetRoutesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRoutesQuery, GetRoutesQueryVariables>, 'query'> & ({ variables: GetRoutesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRoutesComponent = (props: GetRoutesComponentProps) => (
      <ApolloReactComponents.Query<GetRoutesQuery, GetRoutesQueryVariables> query={GetRoutesDocument} {...props} />
    );
    

    export function useGetRoutesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoutesQuery, GetRoutesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetRoutesQuery, GetRoutesQueryVariables>(GetRoutesDocument, baseOptions);
    };
      export function useGetRoutesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoutesQuery, GetRoutesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetRoutesQuery, GetRoutesQueryVariables>(GetRoutesDocument, baseOptions);
      };
      
export type GetRoutesQueryHookResult = ReturnType<typeof useGetRoutesQuery>;
export type GetRoutesQueryResult = ApolloReactCommon.QueryResult<GetRoutesQuery, GetRoutesQueryVariables>;