import React from 'react'
import DingItem from './DingItem';
import { Ding } from '../../../types/generated';
import { em } from 'polished';
import styled from 'styled-components';

const StyledDingContent = styled.div`
  display: flex;
  flex-direction: row;

  .left, .right {
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: ${em(20)};
  }

  .left {
    max-width: 375px;
    flex-basis: 375px;
    margin-right: ${em(20)};
  }

  .right {
    flex-grow: 1;
  }

  /* links */
  a {
    &:hover {
      filter: brightness(1.8);
    }
  }

  /* colors */
  .easy {
    color: green;
  }

  .medium {
    color: yellow;
  }

  .hard {
    color: red;
  }

  @media (max-width: 650px) {
    flex-direction: column;

    .left {
      flex-basis: auto;
      margin-right: ${em(0)};
      margin-bottom: ${em(20)};
    }
  }
`;

interface Props {
  dings: Ding[];
}

export default function DingContent(props: Props) {
  const { dings } = props;

  const leftDings = dings.filter(ding => ['character', 'quests', 'zones'].includes(ding.key));
  const rightDings = dings.filter(ding => ['raid', 'dungeon'].includes(ding.key));

  function getPart(ding) {
    return (
      <div key={ding.key} className={ding.key}>
        <section>
          <h2>{ding.title}</h2>
          <hr />
          <DingItem ding={ding} />
        </section>
      </div>
    );
  }

  return (
    <StyledDingContent>
      <div className="left">{leftDings.map(ding => getPart(ding))}</div>
      <div className="right">{rightDings.map(ding => getPart(ding))}</div>
    </StyledDingContent>
  )
}
