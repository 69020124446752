import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { em } from 'polished';
import { useLocation } from '../hooks';

const StyledRelatedTools = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: ${em(5)};
`;

const links = [
  { link: '/flight-master/', value: 'Flight Master' },
  { link: '/name-generator/', value: 'Name Generator' },
  { link: '/ding/', value: 'Ding' },
];

export default function RelatedTools() {
  const { location: { pathname } } = useLocation();
  return (
    <StyledRelatedTools>
      <h1>More Tools</h1>
      {
        links
          .filter(link => link.link !== pathname)
          .map(link => (
            <Link to={link.link} key={link.value}>
              <div className="link">{link.value}</div>
            </Link>
          ))
      }
    </StyledRelatedTools>
  );
}
