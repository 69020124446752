import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { em } from 'polished';

const ErrorStyledMessage = styled.div`
  color: var(--red-color);
  font-weight: 600;
  padding: ${em(20)};
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`;

interface Props {
  children: ReactNode;
}

export default function ErrorStyled(props: Props) {
  const { children } = props;
  return (
    <ErrorStyledMessage>
      {children}
    </ErrorStyledMessage>
  );
}
