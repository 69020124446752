import Magni from 'name-dios';
import cuss from 'cuss';
import {
  DWARF, GNOME, HUMAN, NIGHTELF, ORC, TAUREN, TROLL, UNDEAD,
} from './data';
import { capitalize } from '../../../shared/utils';

const filteredWords = new Set(Object.keys(cuss));

function createGenerator(names: any[][]) {
  const classicNames = names
    .reduce((acc, val) => acc.concat(val), [])
    .map((item) => item.name)
    .map((word) => word.replace(/[^A-z]/g, ''));
  return new Magni(classicNames, { accuracy: 6, filteredWords });
}

const ALL_RACES = createGenerator([DWARF, GNOME, HUMAN, NIGHTELF, ORC, TAUREN, TROLL, UNDEAD]);

export const RACES = {
  dwarf: createGenerator([DWARF]),
  gnome: createGenerator([GNOME]),
  human: createGenerator([HUMAN]),
  nightelf: createGenerator([NIGHTELF]),
  orc: createGenerator([ORC]),
  tauren: createGenerator([TAUREN]),
  troll: createGenerator([TROLL]),
  undead: createGenerator([UNDEAD]),
};

export async function getNames({
  numWords = 12, startLetters = '', minLength = 4, maxLength = 8, race,
}) {
  const magni = race ? RACES[race] : ALL_RACES;
  const randomBoolean = Math.random() >= 0.5;
  const names = await magni.generate({
    accuracy: 'auto',
    checkLastSlice: randomBoolean,
    maxLength,
    minLength,
    numWords,
    startLetters,
  });
  return names.map((name) => ({
    name: capitalize(name),
    race,
  }));
}

export function getRaces() {
  return Object.keys(RACES);
}
