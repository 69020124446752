import React from 'react'
import { Ding } from '../../../types/generated';

const ConditionalWrap = ({ condition, wrap, children }) => (
  condition ? wrap(children) : children
 );

interface Props {
  ding: Ding;
  hasBullets?: boolean;
}

export default function DingItem(props: Props) {
  const { ding, hasBullets } = props;

  return (
    <ConditionalWrap
      condition={Boolean(hasBullets)}
      wrap={children => (<ul>{children}</ul>)} // Can be anything
    >
      {
        ding.fields.map(field => (
          hasBullets ? (
            <li key={field.description} dangerouslySetInnerHTML={{ __html: field.description }} />
          ): (
            <div key={field.description} dangerouslySetInnerHTML={{ __html: field.description }} />
          )
        ))
      }
    </ConditionalWrap>
  )
}
