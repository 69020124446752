import React, { useContext } from 'react'
import styled from 'styled-components';
import { em } from 'polished';
import DingControls from './DingControls';
import { FormContext } from '../../common/context/form-context';
import DingContentContainer from '../container/DingContentContainer';
import { capitalize } from '../../../shared/utils';

const StyledDing = styled.div`
  h2 {
    color: var(--primaryLight-color);
    margin-bottom: ${em(0)};
    font-family: 'Bitter', serif;
  }

  hr {
    margin-top: ${em(8)};
  }

  ul {
    padding-left: ${em(16)};
  }
`;

export default function Ding() {
  const { state = {} } = useContext(FormContext);

  return (
    <StyledDing>
      <h2>Level {state.level} {capitalize(state.clazz)} ({capitalize(state.race)}) - Phase {state.phase}</h2>
      <hr />
      <DingControls />
      <br />
      <div className="sections">
        <DingContentContainer />
      </div>
    </StyledDing>
  )
}
