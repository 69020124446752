import { Clazz, Race, Faction } from "../../../types/generated";

export const CLASS_RACE_COMBINATIONS: Partial<Record<Clazz, Race[]>> = {
  druid: ['nightelf', 'tauren'],
  hunter: ['dwarf', 'nightelf', 'orc', 'tauren', 'troll'],
  mage: ['gnome', 'human', 'troll', 'undead'],
  paladin: ['dwarf', 'human'],
  priest: ['dwarf', 'human', 'nightelf', 'troll', 'undead'],
  rogue: ['dwarf', 'gnome', 'human', 'nightelf', 'orc', 'troll', 'undead'],
  shaman: ['orc', 'tauren', 'troll'],
  warlock: ['gnome','human','orc','undead'],
  warrior: ['dwarf','gnome','human','nightelf','orc','tauren','troll','undead']
};

export const FACTION_RACE_COMBINATIONS: Partial<Record<Faction, Race[]>> = {
  horde: ['orc', 'tauren', 'troll', 'undead'],
  alliance: ['dwarf', 'gnome', 'human', 'nightelf'],
};
