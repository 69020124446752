import React, { ReactNode, useReducer } from 'react';
import { FormContext } from './form-context';
import { reducerForm } from './form-reducer';

interface Props {
  children: ReactNode;
  initialState?: any;
}

export default function FormProvider(props: Props) {
  const { children, initialState } = props;
  const [state, dispatch] = useReducer(reducerForm, initialState);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
}
