import React from 'react'
import { useGetDingQuery } from '../../../types/generated';
import ErrorStyled from '../../common/components/ErrorStyled';
import Loader from 'react-spinners/BarLoader';
import DingResult from '../components/DingContent';
import { useLocation } from '../../common/hooks';
import { getQueryParams } from '../../common/utils/location';

export default function DingContentContainer() {
  const { location: { search } } = useLocation();
  const queryParams = getQueryParams(search);
  const { level, faction, clazz, phase, race } = queryParams;
  const res = useGetDingQuery({ variables: { level, faction, clazz, phase, race }, fetchPolicy: 'no-cache' });
  const { data, error, loading } = res;

  if (error) {
    return <ErrorStyled>{error.message}</ErrorStyled>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <DingResult dings={data.getDing} />
  )
}
