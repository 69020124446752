/* eslint-disable react/jsx-indent */
import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import Layout from '../client/common/layout/Layout';
import DingContent from '../client/ding/components/Ding';
import SEO from '../client/common/layout/SEO';
import FormProvider from '../client/common/context/form-provider';
import Top from '../client/names/components/Top';
import RelatedTools from '../client/common/components/RelatedTools';

const StyledDing = styled.div`
  color: var(--primaryLight-color);

  h1 {
    margin-bottom: ${em(10)};
  }

  .top {
    margin-bottom: ${em(20)};
  }

  .content {
    background-color: var(--secondaryDarkAlpha-color);
    padding: ${em(20)};
  }
`;

export default function Ding({ location }) {
  return (
    <Layout mainClass="page-container page-padding">
      <SEO
        title="Ding! Levelling Helper"
        description="Ding! A minimal levelling helper and guide for WoW Classic"
        location={location}
        hasSuffix
      />
      <FormProvider>
        <StyledDing>
          <Top
            className="top"
            title="Ding! Levelling Helper"
            description="Find your current levelling area, dungeons, raids and class quests all in one place for WoW Classic."
          />
          <div className="content">
            <DingContent />
          </div>
          <br />
          <hr />
          <RelatedTools />
        </StyledDing>
      </FormProvider>
    </Layout>
  );
}
