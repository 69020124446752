import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useField } from '../../common/hooks';
import { Faction, Clazz, Race } from '../../../types/generated';
import styled from 'styled-components';
import { Clazzez } from '../../../server/api/ding/data';
import { capitalize } from '../../../shared/utils';
import { em } from 'polished';
import { RACES } from '../../../server/api/names/helpers';
import Button from '../../common/components/Button';
import { CLASS_RACE_COMBINATIONS, FACTION_RACE_COMBINATIONS } from './combinations';
import { selectProps } from '../../common/utils/inputs';

const getOptionsLevels = () => Array(60).fill(0).map((_, index) => (
  { value: index + 1, label: index + 1 }
));

const getOptionsFaction = () => ['alliance', 'horde'].map((value) => (
  { value, label: capitalize(value) }
));

const getOptionsPhases = () => [1, 2, 3, 4, 5 ,6].map((value) => (
  { value, label: value }
));

const getOptionsClazzRaces = (faction: Faction) => Object.keys(CLASS_RACE_COMBINATIONS).reduce((prev, curr) => {
  const raceCombs: Race[] = CLASS_RACE_COMBINATIONS[curr].filter(race => FACTION_RACE_COMBINATIONS[faction].includes(race));
  const raceCombsMap = raceCombs.map(race => (
    { value: `${curr} ${race}`, label: `${capitalize(curr)} (${capitalize(race)})` }
  ))
  return prev.concat(raceCombsMap);
}, []);

const getOptionsRaces = () => Object.keys(RACES).map((value) => (
  { value, label: capitalize(value) }
));

const styles = {
  control: (provided) => ({
    ...provided,
    background: 'var(--primaryLight-color)',
  }),
  option: (provided, { data, isFocused }) => ({
    ...provided,
    color: 'black',
    borderBottom: `${em(1)} solid var(--primaryDarkAlpha-color)`,
  }),
};

const StyledDingControls = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    flex-grow: 1;
    margin-right: ${em(10)};
    max-width: ${em(200)};
    min-width: 130px;
    margin-bottom: ${em(10)};
  }

  .buttons {
    display: grid;
    grid-auto-rows: 1fr 1fr;
    grid-gap: ${em(5)};
    margin-top: ${em(22)};
    max-width: 25px;
    min-width: 25px;

    > button {
      color: white;
      font-weight: bold;
      background-color: var(--secondaryDark-color);
      border: 1px solid var(--primaryLight-color);
      cursor: pointer;
    }
  }

  strong {
    display: block;
    margin-bottom: ${em(5)};
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export default function DingControls() {
  const levelQuery = useField<number>('level', 10);
  const factionQuery = useField<Faction>('faction', 'alliance');
  const clazzRaceQuery = useState('warrior human');
  const clazzQuery = useField<Clazz>('clazz', 'warrior');
  const raceQuery = useField<Race>('race', 'human');
  const phaseQuery = useField<number>('phase', 1);

  // class race value
  const [clazzRace, setClazzRace] = clazzRaceQuery;
  const [clazz, setClazz] = clazzQuery;
  const [race, setRace] = raceQuery;
  const [faction] = factionQuery;

  useEffect(() => {
    const [selectedClazz, selectedRace] = clazzRace.split(' ');
    setClazz(selectedClazz);
    setRace(selectedRace);
  }, [clazzRace])

  useEffect(() => {
    setClazzRace(`${clazz} ${race}`);
  }, [])

  function addLevel() {
    const [level, setLevel] = levelQuery;
    if (level < 60) {
      setLevel(level + 1)
    }
  }

  function removeLevel() {
    const [level, setLevel] = levelQuery;
    if (level > 1) {
      setLevel(level - 1)
    }
  }

  const optionsLevels = getOptionsLevels();
  const optionsRaces = getOptionsClazzRaces(factionQuery[0]);
  const optionsFaction = getOptionsFaction();
  const optionsPhases = getOptionsPhases();

  if (!optionsRaces.find(option => option.value === clazzRace)) {
    const defaultClazzRace = faction === 'alliance' ? 'warrior human' : 'warrior orc';
    setClazzRace(defaultClazzRace)
  }

  return (
    <StyledDingControls>
      <div>
        <strong>Level</strong>
        <Select styles={styles} {...selectProps(levelQuery, optionsLevels)} />
      </div>
      <div className="buttons">
        <button onClick={addLevel}>+</button>
        <button onClick={removeLevel}>-</button>
      </div>
      <div>
        <strong>Class + Race</strong>
        <Select styles={styles} {...selectProps(clazzRaceQuery, optionsRaces)} />
      </div>
      <div>
        <strong>Faction</strong>
        <Select styles={styles} {...selectProps(factionQuery, optionsFaction)} />
      </div>
      <div>
        <strong>Phase</strong>
        <Select styles={styles} {...selectProps(phaseQuery, optionsPhases)} />
      </div>
    </StyledDingControls>
  )
}
