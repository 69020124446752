// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory } from '@reach/router';
import { getQueryParamsString } from '../utils/location';

export interface FormAction {
  query?: Record<string, any>;
  type: 'update' | 'trigger';
}

const navigateToState = (state) => {
  const { navigate, location: { pathname } } = globalHistory;
  navigate(`${pathname}?${getQueryParamsString(state)}`, { replace: true });
};

export function reducerForm(state: {}, action: FormAction) {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.query,
      };
    }
    case 'trigger': {
      navigateToState(state);
      return state;
    }
    default:
      return state;
  }
}
