export const checkboxProps = (query) => {
  const [value, setValue] = query;
  return {
    onChange: () => setValue(!value),
    isChecked: value,
  };
};

export const rangeProps = (query) => {
  const [value, setValue] = query;
  return {
    onChange: e => setValue(parseInt(e.target.value, 10)),
    value,
  };
};

export const selectProps = (query, options) => {
  const [value, setValue] = query;
  const selectedValue = options.find(i => i.value === value);
  return {
    onChange: e => setValue(e.value),
    value: selectedValue,
    options,
  };
};

export const textProps = (query) => {
  const [value, setValue] = query;
  return {
    onChange: e => setValue(e.target.value),
    value,
  };
};

export const buttonGroupProps = (query) => {
  const [value, setValue] = query;
  return {
    onChange: v => setValue(v),
    value,
  };
};
